
html, body {
  height: 100%; 
}

.loadingPage
{
  height: 90vh;
}

.mypage
{
  /* padding: 25px 0px 100px 0px; */
  /* background-color: #F8F8F8; */
  max-width: 1400px;
  margin: auto;
  /* min-height: 100vh; */
  min-height: calc(100vh - 290px); 
  height: auto !important; /*Cause footer to stick to bottom in IE 6*/
  height: 100%; 
 
  /* vertical-align:bottom; */
}

.rbc-header {
  border-color: 'red' !important;
  background-color: 'red' !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  border-bottom: solid 1px #EBEBEB;
}
.bottom-tab-nav {
  border-top: solid 1px #EBEBEB;
}
.nav-link,
.bottom-nav-link {
  color: #55575b;
}
.bottom-nav-link.active {
  color: #922c88;
}
.bottom-tab-label {
  font-size: 12px;
}